import { Phone } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import InlineImage from '../../components/InlineEdit/InlineImage'
import InlineTextEdit from '../../components/InlineEdit/InlineTextEdit'
import AppLayout from '../../layout/components/AppLayout'
import authServices from '../authServices'
import ProfileSkeleton from '../components/ProfileSkeleton'

export default function Profile() {
  const [myProfile, setMyProfile] = useState(null);
  

  const getProfileDetails = () => {
    authServices.getProfileDetails().then(res=>{
      setMyProfile(res);
    }).catch(e=>{
      console.log(e)
    })
  }


  useEffect(()=>{
    getProfileDetails();
  },[])


  return (
    <AppLayout>
        <div className="container">
          {!myProfile && <ProfileSkeleton/>}
          {myProfile &&
            <>
            
            {myProfile.verified == 0 &&
            <div className="row">
              <div className="col-md-12">
              <div className="alert alert-danger" role="alert">
                <strong>! </strong> Your profile is pending for approval
              </div>
              </div>
            </div>
            }
            <div className="row mt-3">
              <div className="card col-md-3">
                <div className="row">
                  <div className="col-md-12 p-2">
                    {/*

                        <VerifiedSharp className="float-end text-success me-2" />
                        <span className="badge bg-primary float-end me-2">Dealer</span>
                    */}
                  </div>
                </div>
                
                
                <InlineImage editId={myProfile.id} name="profileImage" value={myProfile.profileImage} handleInlineChange={authServices.updateProfileData}  />
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <InlineTextEdit editId={myProfile.id}  name="firstName" value={myProfile.firstName}  handleInlineChange={authServices.updateProfileData}  fontSize={20} fontWeight={"bold"} />
                      <InlineTextEdit editId={myProfile.id}  name="lastName" value={myProfile.lastName} handleInlineChange={authServices.updateProfileData}  fontSize={20} fontWeight={"bold"} />
                    </div>
                    <div className="col-md-12">
                      
                      <Phone/><InlineTextEdit editId={myProfile.id} name="phone" value={myProfile.phone} handleInlineChange={authServices.updateProfileData}  fontSize={20} fontWeight={"bold"} />
                    { /* <p className="card-text">Dealer Company</p> */ }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          }
        </div>
    </AppLayout>
  )
}
