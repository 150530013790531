import { Add, Grid4x4, PeopleAlt, Print, SupportAgent, VoiceChat } from '@mui/icons-material';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router';
import AppLayout from '../../layout/components/AppLayout';


export default function Dashboard() {

    const dashboardData = false;

    const navigate = useNavigate();

    useEffect(()=>{
        navigate("/customer-portal/1")
    },[])

  return(
    
      <AppLayout>
        <>


        <div className="container">
            <div className="row mt-3">
                

                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            Queue 1
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            Queue 2
                        </div>
                    </div>
                </div>

            </div>
        </div>


        {dashboardData && 
            <div className="container">
            <div className="row" style={{marginTop:"20px"}}>
                <div className="col-md-6">
                <div className="card">
                    <div className="card-header"><VoiceChat/> Products</div>
                    <div className="card-body">
                        <div className="row">
                        {/*  Brands Card  */ }
                        <div className="col-md-6">
                            <div className="card bg-info text-white">
                            <div className="catd-body p-2">
                                <h3 className="card-title">16 Brands</h3>
                            </div>
                            <div className="card-footer">
                                <button type="button" className="btn btn-info"><Add/> Add New</button>
                            </div>
                            </div>
                        </div>

                        {/*  Products Card  */ }
                        <div className="col-md-6">
                            <div className="card bg-success text-white">
                            <div className="catd-body p-2">
                                <h3 className="card-title">1107 Products</h3>
                            </div>
                            <div className="card-footer">
                                <button type="button" className="btn btn-primary"><Add/> Add New</button>
                            </div>
                            </div>
                        </div>  
                        </div>
                    </div>
                    
                </div>
                </div>
                
            <div className="col-md-6">
                <div className="row">



                <div className="col-md-12">
                    <div className="card">
                    <div className="card-header"><PeopleAlt />Customers</div>
                    <div className="card-body">
                        <h3 className="card-title">3043</h3>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-info me-2"> <Grid4x4 /> View List</button>
                        <button className="btn btn-success"> <Add /> Add New</button>
                    </div>
                    </div>
                </div>


                <div className="col-md-12 mt-4">
                    <div className="card">
                    <div className="card-header"><SupportAgent /> Customers Support</div>
                    <div className="card-body">
                        <h3 className="card-title">103 Pending request</h3>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-info me-2"> <Print /> Print Receipt </button>
                        <button className="btn btn-success"> <Add /> Add New Call</button>
                    </div>
                    </div>
                </div>



                </div>
                
                </div>
                


                


            </div>
            </div>
        }
        
        </>
    </AppLayout>

    
 )
}



