import React from 'react'
import Footer from './Footer'
import Header from './Header'

export default function AppLayout({businessDetails,children}) {

    return (
        <>
            <Header businessDetails={businessDetails}/>
                <div className="main" >
                    {children}
                </div>
            <Footer/>

        </>
    )
}
