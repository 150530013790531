import React, { useCallback, useEffect, useState } from 'react'
import { Step, StepLabel, Stepper } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import OTPInput, { ResendOTP } from "otp-input-react";

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import portalServices from '../portalService';
import { Check, Event, Mail, Schedule, WorkOutline } from '@mui/icons-material';

export default function Appointment({queueId,businessId,AppAlert}) {
    var minDate = dayjs();
    var maxDate = minDate.add(15,'day');
    const [selectedDate, setSelectedDate] = React.useState(dayjs());
    const [selectedTime, setSelectedTime] = React.useState();
    const [availableSlots,setAvailableStolts] = useState(null);

    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);

    const [tokenDialogStatus, setTokenDialogStatus] = useState(false);
    const [appointmentDetails,setAppointmentDetails] = useState(null);

    const [savingInProgress, setSavingStatus] = useState(false);

    const [queueDetails,setQueueDetails] = useState(null);
    
    const [loading,setLoadingStatus] = useState(false);
    const [selectedService, setSelectedService] = useState();

    /* Service Work  Start */
    const getQueueDetails = () =>{
        setLoadingStatus(true);
        portalServices.getPortalQueueDetails(queueId).then(res=>{
            setQueueDetails(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }

    useEffect(()=>{
        getQueueDetails();
    },[queueId])


    const selectService = (service) =>{
        setSelectedService(service);
    }

    /* Service Work  Start */


    /*  Stepper Work  Start*/
    const [activeStep,setActiveStep] = useState(0);

    const steps = [
        ["email"],
        ["customerToken"],
        ["firstName","lastName" ],
    ]

    const validateNext = (formik) =>{
        let validationError = false;
        steps[activeStep].forEach(field => {
            formik.setFieldTouched(field);
            formik.validateField(field);
            if(formik.errors && formik.errors[field] !== undefined){
                validationError = true;
            }
        });
        return validationError;
    }

    const handleNext = (formik) =>{
        if(!validateNext(formik) && activeStep < steps.length -1){
            setActiveStep(activeStep => ++activeStep)
        }
    }

    const handlePrevious = (formik) =>{
        if(activeStep > 0){
            setActiveStep(activeStep => --activeStep)
        }
    }

    /*  Stepper Work  End*/



    const handleCalendarChange = (newValue)=>{
        setSelectedDate(newValue);
    }

    const getSlots = (date) => {
        portalServices.getSlots(queueId,date).then(res=>{
            setAvailableStolts(res.slots);
        }).catch(error=>{
            setAvailableStolts([]);
            AppAlert(error.msg,"error")
        });
    }

    useEffect(()=>{
        getSlots(selectedDate.format("YYYY-MM-DD"));
    },[selectedDate,queueId])

    

    const initialValues = {
        serviceId:"",
        selectedDate:"",
        selectedTime:"",
        email:"",
        phone:"",
        customerToken:"",
        firstName:"",
        lastName:""

    }

    const onSubmit = (values, onSubmitProps) => {
        console.log("values",values)
        values['serviceId'] = selectedService.id;


        portalServices.bookAppointment(values).then(res=>{
            AppAlert(res.msg);
            setDialogStatus(false);
            setAppointmentDetails(res.appointmentDetails);
            setOTPError("");
            setTokenDialogStatus(true);
        }).catch(error=>{
            setOTPError(error.msg);
            AppAlert(error.msg,"error");
        })

    }

    const validationRule = Yup.object({
        email:Yup.string().required("Required").email('Invalid email address'),
        customerToken:Yup.string().required("Required"),
        firstName:Yup.string().required("Required"),
        //lastName:Yup.string().required("Required"),
        phone:Yup.string()
        .required()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(10, 'Invalid Phone Number')
        .max(10, 'Invalid Phone Number')
    });


    const bookAppointment = (selectedTime)=>{
        setSelectedTime(selectedTime);
        let autoFill = {
            selectedDate:selectedDate.format("YYYY-MM-DD"),
            selectedTime:selectedTime,
            serviceId:"",
            email:"",
            phone:"",
            customerToken:"",
            firstName:"",
            lastName:""
        }
        setFormValues(autoFill);
        setActiveStep(0);
        setSelectedService(queueDetails.queueBusinessServices[0]);
        setOTPError(null);
        
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }

    const handleTokenDialogClose  = () =>{
        setTokenDialogStatus(false);
    }

    const [OTP, setOTP] = useState("");
    const [OTPError,setOTPError] = useState(null);

    const verifyOtp = (formik) =>{
            setOTPError(null)
            let data ={
                email:formik.values.email,
                businessId:businessId,
                queueId:queueId,
                selectedDate:selectedDate.format("YYYY-MM-DD"),
                selectedTime:selectedTime,
                serviceId:selectedService.id,
                otp:OTP
            };
            portalServices.verifyEmailOTP(data).then(res=>{
                formik.setFieldValue("customerToken", res.data.token);
                //formik.setFieldTouched("customerToken");
                setActiveStep(activeStep => ++activeStep)
                AppAlert("OTP Verified");
            }).catch(error=>{
                setOTPError(error.msg);
                AppAlert(error.msg,"error");
            })

    }


    const requestEmailOTP = (formik) =>{
        if(!validateNext(formik) && activeStep < steps.length -1){
            console.log("email is",formik.values.email);
            let data ={
                email:formik.values.email,
                businessId:businessId,
            };
            portalServices.requestEmailOTP(data).then(res=>{
                setActiveStep(activeStep => ++activeStep)
                AppAlert("OTP Sent");
            }).catch(error=>{
                AppAlert(error.msg,"error");
            })
            //
        }
    }

    const resendOtp = (email) =>{
        let data ={
            email:email,
            businessId:businessId,
        };
        portalServices.resendEmailOtp(data).then(res=>{
            AppAlert("OTP Sent");
        }).catch(error=>{
            AppAlert(error.msg,"error");
        })
    }

    const emailAppointmentDetails =() =>{
        let data ={
            appointmentId:appointmentDetails.id,
        };
        portalServices.emailAppointmentDetails(data).then(res=>{
            AppAlert("Email Sent");
        }).catch(error=>{
            AppAlert(error.msg,"error");
        })
    }

    const getHumanTime =(time) =>{
        let temp = dayjs('2022-11-22T'+time)
        return temp.format("LT");
    }
    

    return (
        <>

            <Dialog
                  open={dialogStatus}
                  keepMounted
                  onClose={handleClose}
                  fullWidth={true}
                  /*maxWidth="md"*/
                  aria-describedby="alert-dialog-slide-description"
            >
  
                  <Formik
                      initialValues={formValues || initialValues}
                      validationSchema={validationRule}
                      onSubmit={onSubmit}
                      enableReinitialize validateOnMount
                  >
                      {
                          (formik) => {
                              //console.log(formik.values);
                              return (
                                  <Form>
                                        <DialogTitle>
                                            <div className="row p-2">
                                                <div className="col-md-12"> 
                                                    <Stepper activeStep={activeStep}>
                                                        <Step>
                                                            <StepLabel>Select Service</StepLabel>
                                                        </Step>
                                                        <Step>
                                                            <StepLabel>OTP Verification </StepLabel>
                                                        </Step>
                                                        <Step>
                                                            <StepLabel>Book My Appointment</StepLabel>
                                                        </Step>
                                                    </Stepper>
                                                </div>
                                            </div>
                                        </DialogTitle>
                                        <DialogContent>
                                          
                                            <div className="row">
                                                <div className="col-sm-4 mt-2 ">
                                                    <div className="card bg-success text-white text-center p-2">
                                                        <span><Event/></span> <span>{selectedDate.format("D MMM, dddd")}</span>
                                                    </div>
                                                </div>
                                            
                                                <div className="col-sm-4 mt-2">
                                                    <div className="card bg-danger text-white text-center p-2">
                                                        <span><Schedule/></span><span> { getHumanTime(formik.values.selectedTime)}</span>

                                                    </div>
                                                </div>
                                                
                                                <div className="col-sm-4 mt-2">
                                                {selectedService  && 
                                                    <div className="card bg-info text-center p-2">
                                                        <span><WorkOutline/> </span>
                                                        <span><strong>{selectedService.title}</strong></span>
                                                    </div>
                                                }
                                                </div>
                                            </div>

                                            {activeStep === 0 && 
                                                <>
                                                    {queueDetails && queueDetails.queueBusinessServices.length > 0 &&
                                                        <>
                                                            <div className="row mt-4">
                                                                <div className="col-md-12 text-center">
                                                                    <h4>Select Service</h4>
                                                                </div>
                                                                <div className="col-md-12 text-center">
                                                                    {queueDetails.queueBusinessServices.map(service=>
                                                                        <span key={service.id}>
                                                                            {selectedService && service.id === selectedService.id ?
                                                                                <button type="button" className="btn btn-sm btn-dark m-2" onClick={()=>selectService(service)} > <Check/> {service.title} </button>
                                                                                :
                                                                                <button type="button" className="btn btn-sm btn-warning m-2" onClick={()=>selectService(service)} >{service.title} </button>
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                    <div className="row mt-4">
                                                        <div className="text-center">
                                                            
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="Email" name="email" 
                                                                className="col-md-6"
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            {activeStep === 1 && 
                                                <>
                                                <div className="row mt-4">
                                                    <div className="col-md-12 text-center">
                                                        <h4>We have sent an otp on <br/> {formik.values.email}</h4>
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-md-12 text-center">
                                                        <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} style={{display:"block"}}  />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <button type="button" className="btn btn-link float-end" onClick={()=>resendOtp(formik.values.email)} >Resend OTP</button>
                                                    </div>
                                                    <div className="col-md-12 text-center">
                                                        {OTPError && <h4 className="mt-3 text-danger">{OTPError}</h4>}
                                                    </div>
                                                </div>

                                                </>
                                            }


                                            {activeStep === 2 && 
                                                <>
                                                <div className="row mt-2">
                                                    <div className="col-md-12 text-center">
                                                        <h4>Your Details</h4>
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-md-12 text-center">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <FormikControl control="input" formik={formik} type="text" 
                                                                    label="First Name" name="firstName"
                                                                />
                                                            </div>
                                                            <div className="col-md-6">
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="Last Name" name="lastName"
                                                            />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <FormikControl control="input" formik={formik} type="text" 
                                                                    label="Phone" name="phone"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </>
                                            }

    
                                            <div className="row">
                                            {  /* JSON.stringify(formik.values) */ }
                                            </div>
    
                                          
                                      </DialogContent>
                                      <DialogActions>
                                        {!savingInProgress && 
                                            <>
                                                <div className="col-md-12">
                                                    <button className="btn  float-end" type="button" onClick={handleClose}>Cancel</button>
                                                    
                                                    {activeStep > 0 && <button type="button" className="btn btn-primary float-start" onClick={() => handlePrevious()}>Back </button> }
                                                    {activeStep === 0 && <button type="button" className="btn btn-primary float-end" onClick={() => requestEmailOTP(formik)}>Continue </button>}
                                                    

                                                    {/* activeStep < steps.length -1 && activeStep !== 1 && <button type="button" className="btn btn-primary float-end" onClick={() => handleNext (formik)}>Next </button> */}
                                                    {activeStep === 1 && <button disabled={OTP.length < 4} type="button" className="btn btn-primary float-end" onClick={() => verifyOtp(formik)}>VerifyOtp </button> }

                                                    {activeStep === steps.length -1  &&
                                                        <button className="btn btn-success float-end" type="submit">Book My Slot</button>
                                                    }

                                                </div>
                                            </>
                                        }
                                        
                                    </DialogActions>
                                  </Form>
                              )
                          }
                      }
                  </Formik>
              </Dialog>

            <Dialog
                  open={tokenDialogStatus}
                  keepMounted
                  onClose={handleTokenDialogClose}
                  fullWidth={true}
                  /*maxWidth="md"*/
                  aria-describedby="alert-dialog-slide-description"
            >
                {appointmentDetails && 
                <>
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                        
                        

                        <div className="row">
                            <div className="col-sm-4 mt-2 ">
                                <div className="card bg-success text-white text-center p-2">
                                    <span><Event/></span> <span>{appointmentDetails.appointmentDate}</span>
                                </div>
                            </div>
                            <div className="col-sm-4 mt-2">
                                <div className="card bg-danger text-white text-center p-2">
                                    <span><Schedule/></span><span> { getHumanTime(appointmentDetails.appointmentTime)}</span>
                                </div>
                            </div>
                            <div className="col-sm-4 mt-2">
                            {selectedService  && 
                                <div className="card bg-info text-center p-2">
                                    <span><WorkOutline/> </span>
                                    <span><strong>{appointmentDetails.serviceTitle}</strong></span>
                                </div>
                            }
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-6 text-center">
                                <h3>Token # : <strong>{appointmentDetails.tokenPrefix}-{appointmentDetails.id}</strong></h3>
                            </div>
                            <div className="col-md-6">
                                <h3>Booked for : <strong>{appointmentDetails.firstName} {appointmentDetails.lastName}</strong></h3>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-12 text-center text-primary">
                                <h3> Get appointment details on </h3><button type="button" className="btn btn-success" onClick={emailAppointmentDetails}> <Mail/> Email</button> 
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions> 
                        <button type="button" className="btn btn-light" onClick={handleTokenDialogClose}>Close</button> 
                        
                    </DialogActions>
                </>
                }
            </Dialog>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header bg-primary text-white">Book Appointment</div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="">
                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                            <StaticDatePicker  
                                                onChange={(newValue) => {
                                                                handleCalendarChange(newValue);
                                                            }}
                                                            displayStaticWrapperAs="desktop"
                                                minDate={minDate}
                                                maxDate={maxDate}
                                                value={selectedDate}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    
                                    {availableSlots && 
                                        
                                        availableSlots.length > 0 ?
                                            <>
                                                <hr/>
                                                <h4>Available Slots</h4>
                                                {availableSlots.map(slot=>{
                                                    return (
                                                        <button key={slot.time} type="button" className="btn btn-sm  btn-dark m-1"   onClick={()=>bookAppointment(slot.time)} >{slot.displayTime}</button>

                                                    )
                                                })}
                                            </>
                                        :
                                        <h3>Sorry we are Closed</h3>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
