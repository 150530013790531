import React, { useCallback, useEffect, useState } from 'react'
import { LinearProgress, Slide, StepContent } from '@mui/material';
import { useNavigate, useParams } from 'react-router';

import { Step, StepLabel, Stepper } from '@mui/material';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import OTPInput, { ResendOTP } from "otp-input-react";


import withAlert from '../../../hoc/withAlert';
import AppLayout from '../../../layout/components/AppLayout';
import portalServices from '../portalService';

function CheckInWithEmail({AppAlert}) {
    let navigate = useNavigate();
    let {businessId, queueId,serviceId } = useParams();
    const [formValues, setFormValues] = useState(null);
    const [savingInProgress, setSavingStatus] = useState(false);


    const initialValues = {
        serviceId: serviceId,
        email:"",
        phone:"",
        customerToken:"",
        firstName:"",
        lastName:""
    }


    const validationRule = Yup.object({
        email:Yup.string().required("Required").email('Invalid email address'),
        customerToken:Yup.string().required("Required"),
        firstName:Yup.string().required("Required"),
        phone:Yup.string()
        .required()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(10, 'Invalid Phone Number')
        .max(10, 'Invalid Phone Number')
    });


    /*  Stepper Work  Start*/
    const [activeStep,setActiveStep] = useState(0);

    const steps = [
        ["email"],
        ["customerToken"],
        ["firstName","lastName" ],
    ]

    const validateNext = (formik) =>{
        let validationError = false;
        steps[activeStep].forEach(field => {
            formik.setFieldTouched(field);
            formik.validateField(field);
            if(formik.errors && formik.errors[field] !== undefined){
                validationError = true;
            }
        });
        return validationError;
    }

    const handleNext = (formik) =>{
        if(!validateNext(formik) && activeStep < steps.length -1){
            setActiveStep(activeStep => ++activeStep)
        }
    }

    const handlePrevious = (formik) =>{
        if(activeStep > 0){
            setActiveStep(activeStep => --activeStep)
        }
    }

    /*  Stepper Work  End*/



    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true);
        portalServices.doCheckin(values).then(res=>{
            AppAlert(res.msg);
            localStorage.setItem('customerToken',JSON.stringify(res.customerToken));
            navigate("/token-details");
            setOTPError("");
        }).catch(error=>{
            setOTPError(error.msg);
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }


    const [OTP, setOTP] = useState("");
    const [OTPError,setOTPError] = useState(null);

    const verifyOtp = (formik) =>{
            setSavingStatus(true);
            setOTPError(null)
            let data ={
                email:formik.values.email,
                businessId:businessId,
                queueId:queueId,
                serviceId:serviceId,
                otp:OTP
            };
            portalServices.verifyEmailOTP(data).then(res=>{
                formik.setFieldValue("customerToken", res.data.token);
                setActiveStep(activeStep => ++activeStep)
                AppAlert("OTP Verified");
            }).catch(error=>{
                setOTPError(error.msg);
                AppAlert(error.msg,"error");
            }).finally(()=>{
                setSavingStatus(false);
            })

    }


    const requestEmailOTP = (formik) =>{
        setSavingStatus(true);
        if(!validateNext(formik) && activeStep < steps.length -1){
            console.log("email is",formik.values.email);
            let data ={
                email:formik.values.email,
                businessId:businessId,
            };
            portalServices.requestEmailOTP(data).then(res=>{
                setActiveStep(activeStep => ++activeStep)
                AppAlert("OTP Sent");
            }).catch(error=>{
                AppAlert(error.msg,"error");
            }).finally(()=>{
                setSavingStatus(false);
            })
            //
        }
    }

    const resendEmailOtp = (email) =>{
        let data ={
            email:email,
            businessId:businessId,
        };
        portalServices.resendEmailOtp(data).then(res=>{
            AppAlert("OTP Sent");
        }).catch(error=>{
            AppAlert(error.msg,"error");
        })
    }

    const emailCheckInDetails =() =>{
        /*
        let data ={
            checkInId:checkInDetails.id,
        };
        portalServices.emailCheckInDetails(data).then(res=>{
            AppAlert("Email Sent");
        }).catch(error=>{
            AppAlert(error.msg,"error");
        })
        */
    }

    return (
        <AppLayout>
        {true && 
            <div className="container">
                <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                    <div className="">
                        <div className="p-2">
                            <Formik
                                initialValues={formValues || initialValues}
                                validationSchema={validationRule}
                                onSubmit={onSubmit}
                                enableReinitialize validateOnMount
                            >
                                {
                                    (formik) => {
                                        //console.log(formik.values);
                                        return (
                                            <Form>
                                                        <div className="row p-2">
                                                            <div className="col-md-12"> 
                                                                <Stepper activeStep={activeStep} orientation="vertical" >
                                                                    <Step>
                                                                        <StepLabel>Contact Details</StepLabel>
                                                                        <StepContent>
                                                                            {activeStep === 0 && 
                                                                                <>
                                                                                    <div className="row mt-4">
                                                                                        <div className="text-center">
                                                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                                                label="Email" name="email" 
                                                                                                className="col-md-6"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </StepContent>
                                                                    </Step>
                                                                    <Step>
                                                                        <StepLabel>Verify Contact Details</StepLabel>
                                                                        <StepContent>
                                                                            {activeStep === 1 && 
                                                                                <>
                                                                                <div className="row mt-4">
                                                                                    <div className="col-md-12 text-center">
                                                                                        <h6 className="text-success">We have sent an otp on <br/> {formik.values.email}</h6>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row mt-2">
                                                                                    <div className="col-md-12 text-center">
                                                                                        <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} style={{display:"block"}}  />
                                                                                    </div>
                                                                                    <div className="col-md-12">
                                                                                        <button type="button" className="btn btn-link float-end" onClick={()=>resendEmailOtp(formik.values.email)} >Resend OTP</button>
                                                                                    </div>
                                                                                    <div className="col-md-12 text-center">
                                                                                        {OTPError && <h4 className="mt-3 text-danger">{OTPError}</h4>}
                                                                                    </div>
                                                                                </div>

                                                                                </>
                                                                            }
                                                                        </StepContent>
                                                                    </Step>
                                                                    <Step>
                                                                        <StepLabel>Book My Appointment</StepLabel>
                                                                        <StepContent>
                                                                            {activeStep === 2 && 
                                                                                    <>
                                                                                    <div className="row mt-2">
                                                                                        <div className="col-md-12 text-center">
                                                                                            <h4>Your Details</h4>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row mt-2">
                                                                                        <div className="col-md-12 text-center">
                                                                                            <div className="row">
                                                                                                <div className="col-md-6">
                                                                                                    <FormikControl control="input" formik={formik} type="text" 
                                                                                                        label="First Name" name="firstName"
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                <FormikControl control="input" formik={formik} type="text" 
                                                                                                    label="Last Name" name="lastName"
                                                                                                />
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <FormikControl control="input" formik={formik} type="text" 
                                                                                                        label="Phone" name="phone"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    </>
                                                                                }
                                                                        </StepContent>
                                                                    </Step>
                                                                </Stepper>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                        {  /* JSON.stringify(formik.values) */ }
                                                        </div>
                
                                                    
                                                
                                                
                                                    {!savingInProgress && 
                                                        <>
                                                            <div className="col-md-12">
                                                                <button className="btn  float-end" type="button" >Cancel</button>
                                                                {activeStep > 0 && <button type="button" className="btn btn-primary float-start" onClick={() => handlePrevious()}>Back </button> }
                                                                {activeStep === 0 && <button type="button" className="btn btn-primary float-end" onClick={() => requestEmailOTP(formik)}>Continue </button>}
                                                                {/* activeStep < steps.length -1 && activeStep !== 1 && <button type="button" className="btn btn-primary float-end" onClick={() => handleNext (formik)}>Next </button> */}
                                                                {activeStep === 1 && <button disabled={OTP.length < 4} type="button" className="btn btn-primary float-end" onClick={() => verifyOtp(formik)}>VerifyOtp </button> }
                                                                {activeStep === steps.length -1  &&
                                                                    <button className="btn btn-success float-end" type="submit">Check In </button>
                                                                }
                                                            </div>
                                                        </>
                                                    }

                                                    {savingInProgress && <LinearProgress/>}

                                                    
                                                
                                            </Form>
                                        )
                                    }
                                }
                            </Formik>
                        </div>
                    </div>
                </Slide>
            </div>
        }
        </AppLayout>
    )
}

export default withAlert(CheckInWithEmail)