import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import AppLayout from '../../../layout/components/AppLayout'
import portalServices from '../portalService';
import withAlert from '../../../hoc/withAlert';
import Appointment from '../components/Appointment';
import PortalQueueDetails from '../components/PortalQueueDetails';
import CheckIn from '../components/CheckIn';




const BookingPortal = ({AppAlert}) => {

    let { businessId } = useParams()
    let [selectedQueue,setSelectedQueue] = useState(null);
    let [selectedQueueType,setSelectedQueueType] = useState(null) ;



    const [portalDetails,setPortalDetails] = useState(null);
    const getBusinessDetails = useCallback(()=>{
            portalServices.getPortalDetails(businessId).then(res=>{
                setPortalDetails(res.data)
                setSelectedQueue(res.data.queueList[0].id);
                setSelectedQueueType(res.data.queueList[0].queueType);
            }).catch(error=>{
                AppAlert(error.msg,"error")
            })
    },[businessId]);

    useEffect(()=>{
        getBusinessDetails()
    },[])

    


    return (
        <>
            {portalDetails &&
                <AppLayout businessDetails={portalDetails.businessDetails}>
                <div className="container">
                        <div className="row mt-3">
                            {portalDetails.queueList  && portalDetails.queueList.length > 1 &&
                                portalDetails.queueList.map(queue =>{
                                    return(
                                        <div className="col-md-4" key={queue.id} onClick={()=>{setSelectedQueue(queue.id); setSelectedQueueType(queue.queueType)}}>
                                            <div className={"card "} >
                                                <div className={"card-body " + (selectedQueue && queue.id == selectedQueue ? "bg-success" : "")} >
                                                    {queue.title}
                                                </div>
                                            </div>
                                        </div>
                                        
                                    )
                                })
                            }
                        </div>
                        <div className="row mt-2">


                            <div className="col-md-6">
                                {selectedQueueType === 1 && 
                                    <Appointment queueId={selectedQueue} businessId={businessId} AppAlert={AppAlert} />
                                }
                                {selectedQueueType === 2 && 
                                    <CheckIn queueId={selectedQueue} businessId={businessId} AppAlert={AppAlert} />
                                }
                            </div>


                            <div className="col-md-6">
                                <PortalQueueDetails queueId={selectedQueue} AppAlert={AppAlert} queueType={selectedQueueType} />
                            </div>
                        </div>
                    </div>
                </AppLayout>
            }
        </>
    )
}


export default withAlert(BookingPortal);