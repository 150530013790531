import React from 'react'
import { Route, Routes } from 'react-router'
import Login from '../auth/pages/Login'
import Logout from '../auth/pages/Logout'
import Register from '../auth/pages/Register'
import Profile from '../auth/pages/Profile'
import Dashboard from '../dashboard/pages/Dashboard'
import BookingPortal from '../app/portal/pages/BookingPortal'
import CustomerPortal from '../app/portal/pages/CustomerPortal'
import QueuePage from '../app/portal/pages/QueuePage'
import CheckInWithEmail from '../app/portal/pages/CheckInWithEmail'
import TokenDetails from '../app/portal/pages/TokenDetails'
import CheckInWithSms from '../app/portal/pages/CheckInWithSms'





export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={ <Dashboard/> } />
      <Route path="/auth/login" element={ <Login/> } />
      <Route path="/auth/register" element={ <Register/> } />
      <Route path="/auth/profile" element={ <Profile/> } />
      <Route path="/auth/logout" element={ <Logout/> } />
      
      <Route path="/booking-portal/:businessId" element={ <BookingPortal /> } />
      <Route path="/customer-portal/:businessId" element={  <CustomerPortal/> } />

      
      <Route path="/portal/:businessId" element={  <CustomerPortal/> } />
      <Route path="/queue/:queueId" element={  <QueuePage/> } />
      
      { /* <Route path="/checkin/:businessId/:queueId/:serviceId" element={ <CheckInWithEmail/> } /> */ }

      { <Route path="/checkin/:businessId/:queueId/:serviceId" element={ <CheckInWithSms/> } />  }

      <Route path="/token-details" element={ <TokenDetails /> } />

    </Routes>
  )
}
