import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@mui/material";

import api from "../../api/api";


import { Edit } from '@mui/icons-material';
import { Alert, Snackbar } from '@mui/material'
import { useSelector } from 'react-redux';


export default function InlineImage({name, value, editId, handleInlineChange}) {

  
  const [dialogStatus, setDialogStatus] = useState(false);
  const [uploadInProgress, setUploadProgress] = useState(null);
  const [inputValue,setInputValue] = useState(value);
  const [imagePath,setImagePath] = useState(null);
  const [uploaded ,setUploadStatus] =  useState(false);
  const [snackStatus, setSnackStatus] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackType, setSnackType] = useState("error");

  const authUser = useSelector((state) => state.auth)
  const userId=  authUser.id;


  const startUpload = (e) =>{

    console.log("I am ahere");
    let data = new FormData();
    data.append('tmpFile', e.target.files[0]);
    setUploadStatus(false);
    api.post("upload/file",data,{
        onUploadProgress: (progressEvent) => { 
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            //console.log(percent);
            setUploadProgress(percent)
        }
    }).then( (res)=>{
        

          setUploadProgress(null)
          setUploadStatus(true);
          setInputValue(res.data.fileName);
          setImagePath(res.data.filePath);

          


        

        console.log(res);
    }).catch( (error) => {
        setUploadProgress(null)
        console.log(error);
        
    })
  }

  const addNew = ()=>{
      setDialogStatus(true)
  }

  const handleClose  = () =>{
      setDialogStatus(false);
  }

  const prepairImagePath = () =>{
    if(inputValue){
      let imageURL = process.env.REACT_APP_BASE_URL+"/uploads/images/"+inputValue;
      setImagePath(imageURL);
    } else {
      setImagePath("/images/blank-profile.png")
    }
  }



  const  updateImage = () =>{
    let data = {};
    data[name] = inputValue;
    let payload = {
        data:data,
        editId:editId,
        type:"image",
        userId:userId,
    }
    
    handleInlineChange(payload).then(res=>{
        setSnackStatus(true);
        setSnackType("success");
        setSnackMessage(res.msg);
    }).catch(error => {
        console.log(error);
        setSnackStatus(true);
        setSnackType("error");
        setSnackMessage(error.message);
    })
  }

  useEffect(()=>{
    prepairImagePath();
  },[])


  return (
    <>
          <div className="v2-img-container">
              <img className="v2-image" src={imagePath} />
              <div className="v2-middle">
                <button type="button" className="btn btn-danger btn-sm" onClick={addNew}>Change</button>
            </div>
          </div>
      
          <Snackbar open={snackStatus} onClose={ ()=>{setSnackStatus(false)} } autoHideDuration={10000}  anchorOrigin={{ vertical: 'top', horizontal: 'center',  }}>
              <Alert  severity={snackType} sx={{ width: '100%' }}> {snackMessage}</Alert>
          </Snackbar>

          <Dialog
              open={dialogStatus}
              keepMounted
              onClose={handleClose}
              fullWidth={true}
              maxWidth="md"
              aria-describedby="alert-dialog-slide-description"
          >

          <DialogTitle>{"Update Image"}</DialogTitle>
              <DialogContent>

                <div className="row">
                

                { uploadInProgress !== null && uploadInProgress < 100 && <LinearProgress variant="determinate" value={uploadInProgress} /> }
                { uploadInProgress === null && <img className="img-thumbnail v2-thumb" src={imagePath} />}
                  
                </div>
                <div className="row">
                  <div className="col-md-12 mt-2">
                    <input type="file" onChange={ (e)=>{ startUpload(e) } }  />
                    { /*<label htmlFor="upload" className="btn btn-primary  float-end">Choose File</label> */ }
                  </div>
                  
                </div>

                
                
              </DialogContent>
              <DialogActions>
                {uploaded  && <button type="button" className="btn btn-success" onClick={updateImage} >Update</button> }
                <button type="button" className="btn btn-danger" onClick={handleClose} >Close</button>
              </DialogActions>
          </Dialog>
    </>
  )
}
