import React, { useEffect, useState } from 'react'
import portalServices from '../portalService';

import ExpandableCard from '../../../components/ExpandableCard';
import CommonLoadingSkeleton from '../../../components/CommonLoadingSkeleton';


export default function PortalQueueDetails({queueId,AppAlert,queueType}) {

    const [queueDetails,setQueueDetails] = useState(null);
    const [loading,setLoadingStatus] = useState(false);
    const getQueueDetails = () =>{
        setLoadingStatus(true);
        portalServices.getPortalQueueDetails(queueId).then(res=>{
            setQueueDetails(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }

    useEffect(()=>{
        getQueueDetails();
    },[queueId])

    const getWeekDayName = (dayNo) =>{
        let weekDays = ["","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"]
        return weekDays[dayNo];
    }

    return (
        <>
            {queueType == 1 &&
            <ExpandableCard title="Services">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                        {loading && <CommonLoadingSkeleton /> }
                        {queueDetails && 
                                <ul>
                                    {queueDetails.queueBusinessServices.map((service,index)=>
                                        <li key={index}>
                                            {service.title}
                                        </li>
                                    )}
                                </ul>
                        }
                        </div>
                    </div>
                </div>
            </ExpandableCard>
            }
            <ExpandableCard title="Working Hours">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                        {loading && <CommonLoadingSkeleton /> }
                        {queueDetails && 
                            <table className="table">
                                <tbody>
                                    {queueDetails.queueBusinessHours.map(day=>
                                        <tr key={day.dayNo}>
                                            <th>{getWeekDayName(day.dayNo)}</th>
                                            <td>
                                                <span className="float-end">
                                                    { day.dayStatus ? 
                                                            <>{day.dayStart} - {day.dayEnd}</>
                                                        :
                                                        <span>Closed</span>
                                                    }
                                                </span>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        }
                        </div>
                    </div>
                </div>
            </ExpandableCard>

        </>
    )
}
