import React from 'react'
import { Skeleton } from '@mui/material'

export default function ProfileSkeleton () {
    return (
      <>    <div className="row">
              <div className="col-md-4">
                <div className="card p-2 m-2">
                  <Skeleton animation="wave" variant={"circle"} height={200} />
                  <Skeleton
                        animation="wave"
                        height={30}
                    />
                </div>
              </div>
              <div className="col-md-8">
                <div className="card p-2 m-2">
                    <Skeleton
                        animation="wave"
                        height={50}
                        width="30%"
                    />
                    <Skeleton
                        animation="wave"
                        height={40}
                        width="100%"
                    />
                    <Skeleton
                        animation="wave"
                        height={40}
                        width="100%"
                    />
                </div>
                <div className="card p-2 m-2">
                    <Skeleton
                        animation="wave"
                        height={50}
                        width="30%"
                    />
                    <Skeleton
                        animation="wave"
                        height={40}
                        width="100%"
                    />
                    <Skeleton
                        animation="wave"
                        height={40}
                        width="100%"
                    />
                </div>
              </div>
              </div>
      </>
    )
  }