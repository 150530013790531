import { Edit } from '@mui/icons-material';
import React, { useRef, useState } from 'react'
import { Alert, Snackbar } from '@mui/material'
import { useSelector } from 'react-redux';

export default function InlineTextEdit({label,name, editId, value, handleInlineChange,className,fontSize, fontWeight}) {

  const [editing,setEditingStatus] = useState(false)
  const [inputValue,setInputValue] = useState(value);
  const [showEditIcon,setEditIconStatus] = useState(false);

  const [snackStatus, setSnackStatus] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackType, setSnackType] = useState("error");

  const authUser = useSelector((state) => state.auth)
  const userId=  authUser.id;
  
  const inputRef = useRef(null)

  const handleKeyUp = (e) =>{
    if(e.key === 'Enter') {
        e.preventDefault();
        updateValue();
    }
  }

  const handleChange = (e) =>{
    setInputValue(e.target.value);
  }

  const  updateValue = () =>{
    setEditingStatus(false); 
    setEditIconStatus(false)
    let data = {};
    data[name] = inputValue;
    let payload = {
        data:data,
        editId:editId,
        type:"text",
        userId:userId,
    }
    handleInlineChange(payload).then(res=>{
        setSnackStatus(true);
        setSnackType("success");
        setSnackMessage(res.msg);
    }).catch(error => {
        console.log(error);
        setSnackStatus(true);
        setSnackType("error");
        setSnackMessage(error.message);
    })
    
  }

  const enableEditing = () =>{
    setEditingStatus(true); 
    setTimeout(()=>{
        inputRef.current.focus()
    },100)
  }
  
  return (
    <>
            <Snackbar open={snackStatus} onClose={ ()=>{setSnackStatus(false)} } autoHideDuration={10000}  anchorOrigin={{ vertical: 'top', horizontal: 'center',  }}>
                <Alert  severity={snackType} sx={{ width: '100%' }}> {snackMessage}</Alert>
            </Snackbar>
            { label ?
                <div className={className}  style={{fontSize:fontSize,fontWeight:fontWeight}}>
                    <div className="row" style={{position:"relative"}} >
                        <div className="col-md-6"><strong>{label}</strong></div>
                        {!editing && 
                            <div className={"col-md-6"} style={{position:"relative"}}
                                onClick={enableEditing} 
                                onMouseEnter={()=>setEditIconStatus(true)} 
                                onMouseLeave={()=>setEditIconStatus(false)} >
                                    {inputValue === "" || !inputValue ? "NA" : inputValue} <span className="v2-inlineTextEditIconWithLabel" >{showEditIcon &&  <Edit  /> }</span>
                            </div> 
                        }
                        {editing && 
                            <div className="col-md-6" style={{position:"absolute",right:0}}>
                                <input type="text" ref={inputRef} className="form-control" value={inputValue} 
                                    onChange={ (e)=> { handleChange(e)} } 
                                    onKeyUp={ (e)=> { handleKeyUp(e)} } 
                                    onBlur={updateValue} 
                                />
                            </div>
                        }
                    </div>
                </div>
            :
                <>
                    {/* <div className={className}  style={{fontSize:fontSize,fontWeight:fontWeight}}> */ }
                        {!editing && 
                            <span
                                style={{fontSize:fontSize,fontWeight:fontWeight,position:"relative"}}
                                onClick={enableEditing} 
                                onMouseEnter={()=>setEditIconStatus(true)} 
                                onMouseLeave={()=>setEditIconStatus(false)} >
                                    {inputValue === "" || !inputValue ? "NA" : inputValue}  <span className="v2-inlineTextEditIcon" >{showEditIcon ?  <Edit />: <span className=""></span> }</span>
                            </span> 
                        }
                        {editing && 
                            <span >
                                <input type="text" ref={inputRef} className="form-control" value={inputValue} 
                                    onChange={ (e)=> { handleChange(e)} } 
                                    onKeyUp={ (e)=> { handleKeyUp(e)} } 
                                    onBlur={updateValue} 
                                />
                            </span>
                        }
                    {/* </div>*/ }
                </>
            }
        
    </>
  )
}