import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const API = axios.create({
    baseURL: baseURL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
});


API.interceptors.request.use(
    config => {
        if(localStorage.authUser){  // If user is Loggedin 
            let authUser = JSON.parse(localStorage.authUser);
            let token = authUser.token;
            config.headers.Authorization = `Bearer ${token}`;
      } else {
        delete API.defaults.headers.common.Authorization;
      }
      return config;
    },
  
    error => Promise.reject(error)
  );
  
  export default API;

