import { Slide } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import withAlert from '../../../hoc/withAlert';
import AppLayout from '../../../layout/components/AppLayout'
import Appointment from '../components/Appointment';
import { KeyboardArrowRight } from '@mui/icons-material'
import portalServices from '../portalService';

const QueuePage = ({AppAlert}) => {

    let { queueId } = useParams();
    const navigate = useNavigate();

    const [queueDetails,setQueueDetails] = useState(null);
    const [loading,setLoadingStatus] = useState(false);

    const getQueueDetails = () =>{
        setLoadingStatus(true);
        portalServices.getPortalQueueDetails(queueId).then(res=>{
            setQueueDetails(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }

    useEffect(()=>{
        getQueueDetails();
    },[queueId])

    const selectService  = (serviceId) =>{
        //console.log(serviceId);
        //console.log(queueDetails);
        if(queueDetails.queueDetails.queueType === 2){
            navigate("/checkin/"+queueDetails.queueDetails.businessId+"/"+queueId+"/"+serviceId);
        }
    }

    return (
        <AppLayout>
            {queueDetails && 
                <div className="container">
                    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                        <ol className="list-group list-group-numbered">
                            {
                                queueDetails.queueBusinessServices.map(service =>
                                    <button type="button" onClick={()=>selectService(service.id) } key={service.id} className="list-group-item list-group-item-action d-flex justify-content-between align-items-start p-3">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">{service.title}</div>
                                        </div>
                                        <span className=""><KeyboardArrowRight/></span>
                                    </button>
                                )
                            }
                        </ol>
                    </Slide>
                </div>
            }
        </AppLayout>
    )
}

export default withAlert(QueuePage)